import { Tr } from "@chakra-ui/react";
import { MessageOut } from "svix";

import TableCell from "@svix/common/widgets/TableCell";
import TimestampTableCell from "@svix/common/widgets/TimestampTableCell";

import { routeResolver } from "src/App";

export default function MessageRow(props: { msg: MessageOut; showChannels: boolean }) {
  const { msg } = props;
  const to = routeResolver.getRoute("messages._id", { msgId: msg.id });

  return (
    <Tr>
      <TableCell to={to} mono>
        {msg.eventType}
      </TableCell>
      <TableCell to={to} mono isTruncated maxW={0} w="40%">
        {msg.eventId ?? msg.id}
      </TableCell>
      {props.showChannels && (
        <TableCell to={to} mono maxW={0}>
          {msg.channels}
        </TableCell>
      )}
      <TimestampTableCell to={to} ts={msg.timestamp} />
    </Tr>
  );
}
