import * as React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
  Text,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteRefMethods,
  AutoCompleteInput,
  AutoCompleteList,
  AutoCompleteTag,
  AutoCompleteCreatable,
} from "@choc-ui/chakra-autocomplete";
import { FieldError, useController } from "react-hook-form";

import { MAX_CHANNELS } from "./Channels";

interface IAutocompleteProps extends InputProps {
  control: any;
  name: string;
  label?: React.ReactNode | string;
  helperText?: React.ReactNode | string;
  required?: boolean;
  maxChannels?: number;
}

function getErrorMessage(error: FieldError | undefined): string {
  if (!error) {
    return "";
  }
  if (error.message) {
    return error.message;
  }
  if (error.type === "required") {
    return "Required";
  }
  if (error.type === "maxLength") {
    return "Max length exceeded";
  }
  return "Invalid";
}

export default function ChannelsAutocomplete({
  control,
  helperText,
  label,
  name,
  placeholder,
  required,
  maxChannels = MAX_CHANNELS,
  ...inputProps
}: IAutocompleteProps) {
  const autocomplete = React.useRef<AutoCompleteRefMethods>();
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: [],
  });
  const { ref, ...controlProps } = field;
  const value = field.value || [];

  const errorMessage = getErrorMessage(error);

  return (
    <FormControl isRequired={required} isInvalid={invalid}>
      {label !== undefined && <FormLabel>{label}</FormLabel>}
      <AutoComplete
        ref={autocomplete}
        creatable
        openOnFocus
        multiple
        maxSelections={maxChannels}
        submitKeys={[" "]}
        onChange={(nextValue: string[]) => {
          controlProps.onChange(nextValue.filter((v) => v !== ""));
        }}
        value={value}
      >
        <AutoCompleteInput
          placeholder={placeholder ?? "Type to add..."}
          variant="filled"
          size="sm"
          borderRadius="md"
          width="unset"
          {...inputProps}
          onKeyDown={(e) => {
            if ((e.target as HTMLInputElement).value !== "") {
              return;
            }
            /* Delete last item with backspace */
            if (e.key === "Backspace" || e.key === "Delete") {
              autocomplete.current?.removeItem(value[value.length - 1]);
            }
          }}
        >
          {({ tags }) =>
            tags.map((tag, tid) => (
              <AutoCompleteTag key={tid} label={tag.label} onRemove={tag.onRemove} />
            ))
          }
        </AutoCompleteInput>
        <AutoCompleteList>
          {value.length < maxChannels && (
            <AutoCompleteCreatable mb={2}>
              {({ value }) => <span>Add "{value}" as channel</span>}
            </AutoCompleteCreatable>
          )}
          <Flex mx={2} px={2}>
            <Text variant="caption">
              {`You can add up to ${maxChannels} channels per endpoint.`}
            </Text>
          </Flex>
        </AutoCompleteList>
      </AutoComplete>
      {helperText !== undefined && !error && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
