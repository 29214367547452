import { ButtonGroup, Flex, Text, Tooltip } from "@chakra-ui/react";
import { EndpointIn, EndpointOut } from "svix";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";

interface IRateLimitProps {
  endpoint: EndpointOut;
}

export default function RateLimit(props: IRateLimitProps) {
  const { endpoint } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const queryKey = ["endpoints", endpoint.id];

  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<EndpointOut>(queryKey, endpoint, "rateLimit", async (form) => {
      if (form.rateLimit === 0) {
        delete form.rateLimit;
      }
      if (typeof form.rateLimit === "string") {
        if (form.rateLimit === "" || form.rateLimit === "0") {
          delete form.rateLimit;
        } else {
          form.rateLimit = parseInt(form.rateLimit);
        }
      }
      const endpointIn = { ...form } as EndpointIn;
      const api = getSvix();
      return api.endpoint.update(user.app.id, endpoint.id, endpointIn);
    });

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  return (
    <Form onSubmit={mutate} {...formCtx}>
      <Card
        maxH={460}
        overflowY="scroll"
        title="Rate Limit"
        cta={
          isEditing ? (
            <ButtonGroup>
              <Button colorScheme="gray" size="xs" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" size="xs" isLoading={isSubmitting}>
                Save
              </SubmitButton>
            </ButtonGroup>
          ) : (
            <ReadOnlyTooltip readOnly={isReadOnly}>
              <Button
                type="button"
                size="xs"
                colorScheme="gray"
                onClick={edit}
                isDisabled={isReadOnly}
              >
                Edit
              </Button>
            </ReadOnlyTooltip>
          )
        }
      >
        {isEditing ? (
          <Flex maxW="16em" alignItems="center">
            <TextField
              textAlign="right"
              autoFocus
              name="rateLimit"
              type="number"
              control={formCtx.control}
            />
            <Text ml={2}>RPS</Text>
          </Flex>
        ) : typeof endpoint.rateLimit === "number" ? (
          <Text>
            {endpoint.rateLimit} <Tooltip label="Requests per second">RPS</Tooltip>
          </Text>
        ) : (
          <Text variant="caption" fontStyle="italic">
            No rate limit
          </Text>
        )}
      </Card>
    </Form>
  );
}
