import { lazy, Suspense } from "react";
import {
  Box,
  Heading,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StackDivider,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { format } from "d3-format";
import { useQuery } from "react-query";
import { AttemptStatisticsResponse, StatisticsApi } from "svix/dist/openapi";

import Card from "@svix/common/widgets/Card";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";

const TimeIntervalLineChart = lazy(
  () => import("@svix/common/widgets/TimeIntervalLineChart")
);

const FIVE_MINUTES = 1000 * 60 * 5;

export function getStatsPeriod(stats?: AttemptStatisticsResponse): string {
  if (!stats) {
    return "";
  }

  const from = stats.startDate.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });
  const to = stats.endDate.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });

  return `Last 6 hours (${from} - ${to})`;
}

function transformStats(stats: AttemptStatisticsResponse) {
  const startTime = new Date(stats.startDate);
  const successData = (stats.data.successCount || []).map((successCount, idx) => ({
    x: new Date(startTime.getTime() + idx * FIVE_MINUTES),
    y: successCount,
  }));
  const failureData = (stats.data.failureCount || []).map((failureCount, idx) => ({
    x: new Date(startTime.getTime() + idx * FIVE_MINUTES),
    y: failureCount,
  }));

  return [
    {
      id: "success",
      data: successData,
    },
    {
      id: "failure",
      data: failureData,
    },
  ];
}

function formatNumber(value: number): string {
  return format(",d")(value);
}

interface IAttemptsStatProps {
  stats: AttemptStatisticsResponse | undefined;
}

export function TotalSuccessfulStat({ stats }: IAttemptsStatProps) {
  const successCounts: number[] = stats?.data.successCount ?? [];
  const totalMessages = successCounts.reduce((a, b) => a + b, 0);

  return (
    <Box pr={12}>
      <Stat>
        <StatLabel>Successful Attempts</StatLabel>
        <Skeleton isLoaded={!!stats} maxW="4em">
          <StatNumber w="fit-content">{formatNumber(totalMessages)}</StatNumber>
        </Skeleton>
      </Stat>
    </Box>
  );
}

export function TotalFailuresStat({ stats }: IAttemptsStatProps) {
  const failureCounts: number[] = stats?.data.failureCount ?? [];
  const totalMessages = failureCounts.reduce((a, b) => a + b, 0);

  return (
    <Box pr={12}>
      <Stat>
        <StatLabel>Failed Attempts</StatLabel>
        <Skeleton isLoaded={!!stats} maxW="4em">
          <StatNumber w="fit-content">{formatNumber(totalMessages)}</StatNumber>
        </Skeleton>
      </Stat>
    </Box>
  );
}

export default function UsageScreen() {
  const user = useAppSelector((state) => state.auth.user)!;
  const { data: stats, isLoading } = useQuery("stats", async () => {
    const dh = getSvix();
    const config = dh._configuration;
    const statsApi = new StatisticsApi(config);

    return statsApi.v1StatsAppAttempts({
      appId: user.app.id,
    });
  });

  return (
    <>
      <MetaTitle path={["Activity"]} />
      <Heading as="h1" my={2} size="lg">
        Historical Attempts
      </Heading>
      <Text mb={8} variant="caption">
        {getStatsPeriod(stats)}
      </Text>
      <Stack
        direction="row"
        mb={12}
        spacing={5}
        divider={<StackDivider borderColor="background.modifier.border" />}
      >
        <TotalSuccessfulStat stats={stats} />
        <TotalFailuresStat stats={stats} />
      </Stack>
      <Card title="Recent Activity" w="100%">
        <Suspense fallback={<Skeleton h="320px" w="100%" />}>
          <Box height="320px" w="100%" position="relative">
            <TimeIntervalLineChart
              isLoading={isLoading}
              startTime={stats?.startDate ?? new Date()}
              endTime={stats?.endDate ?? new Date()}
              data={stats ? transformStats(stats) : []}
              emptyState={
                <>
                  <Heading textAlign="center" as="h3" size="md" mb={2}>
                    No attempts have been made to your endpoints in the last 6 hours.
                  </Heading>
                </>
              }
            />
          </Box>
        </Suspense>
      </Card>
    </>
  );
}
