import { useState } from "react";
import {
  Divider,
  Box,
  Collapse,
  Flex,
  Stack,
  HStack,
  useDisclosure,
  useRadioGroup,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowForwardIos } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { EnvironmentSettingsApi } from "svix/dist/openapi";
import * as yup from "yup";

import { setErrors } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextArea from "@svix/common/widgets/form/TextArea";
import TextField from "@svix/common/widgets/form/TextField";
import IntegrationTile from "@svix/common/widgets/IntegrationTile";
import StyledLink from "@svix/common/widgets/Link";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix, SinkIn, SinksApi, SinkTypeName } from "src/api";
import { routeResolver } from "src/App";
import { useAllEventTypes, useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import sqsIcon from "src/img/sinks/aws-sqs.svg";
import rabbitMQIcon from "src/img/sinks/rabbitmq.svg";
import ChannelsAutocomplete from "../../Endpoint/properties/ChannelsAutosuggest";

const schema = yup.object().shape({
  description: yup.string(),
  filterTypes: yup.mixed(),
  channels: yup.array().of(yup.string()).max(3).nullable(),
  rateLimit: yup
    .number()
    .nullable()
    .transform((_, val) => (val !== "" && val !== null ? Number(val) : null)),
});

const DEFAULT_VALUES = {
  filterTypes: [] as string[],
};

const SinkTypes: string[] = ["rabbitMQ", "sqs"];

const SinkIcons: { [k: string]: string } = {
  rabbitMQ: rabbitMQIcon,
  sqs: sqsIcon,
};

export function SinkCreateForm() {
  const user = useAppSelector((state) => state.auth.user)!;
  const history = useHistory();
  const formCtx = useForm<SinkIn>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  const { data: orgSettings } = useAppQuery(["orgSettings"], async () => {
    const sv = getSvix();
    const config = sv._configuration;
    const api = new EnvironmentSettingsApi(config);
    return api.v1EnvironmentGetSettings({});
  });

  const { data: availableEvents } = useAllEventTypes();

  const [sinkType, setSinkType] = useState<SinkTypeName | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "integration",
    defaultValue: "",
    onChange: (n: SinkTypeName) => {
      const chosenSink = SinkTypes.indexOf(n);
      if (chosenSink >= 0) {
        setSinkType(n);
        onOpen();
      } else {
        onClose();
      }
    },
  });
  const group = getRootProps();

  async function onAddSink(form: SinkIn) {
    if (form.filterTypes && form.filterTypes.length === 0) {
      delete form["filterTypes"];
    }
    if (form.channels && form.channels.length === 0) {
      delete form["channels"];
    }

    try {
      const sink: SinkIn = {
        ...form,
        type: sinkType as any,
      };
      const sinksApi = SinksApi();
      const res = await sinksApi.createSink(user.app.id, sink);
      history.push(routeResolver.getRoute("sinks._id", { endpId: res.id }));
    } catch (e) {
      setErrors(formCtx.setError, e);
    }
  }

  return (
    <Form onSubmit={onAddSink} {...formCtx}>
      <Box maxW="50em">
        <Wrap {...group}>
          {SinkTypes.map((s) => {
            const radio = getRadioProps({ value: s });

            return (
              <WrapItem key={s}>
                <IntegrationTile
                  key={s}
                  {...radio}
                  icon={SinkIcons[s] || ""}
                  name={s}
                  description={s}
                  wrapper={{ w: "24em" }}
                />
              </WrapItem>
            );
          })}
        </Wrap>

        <Collapse in={isOpen}>
          <Stack spacing={5}>
            <Box maxHeight="300px" mb={4}></Box>

            <TextArea
              autoFocus
              name="description"
              control={formCtx.control}
              label="Description"
              placeholder="An optional description of what this sink is used for."
            />

            {sinkType === "rabbitMQ" && (
              <>
                <TextField
                  control={formCtx.control}
                  name="uri"
                  label="AMQPS URL"
                  type="url"
                  isRequired
                  placeholder="e.g. https://www.example.com/webhook"
                  helperText={<span>Configure a RabbitMQ endpoint</span>}
                />
                <TextField
                  control={formCtx.control}
                  name="routingKey"
                  label="Routing Key"
                  type="text"
                  isRequired
                  placeholder="e.g. key-1"
                />
              </>
            )}

            {sinkType === "sqs" && (
              <>
                <TextField
                  control={formCtx.control}
                  name="region"
                  label="Region"
                  type="text"
                  isRequired
                  placeholder="e.g. eu-west-1"
                />
                <TextField
                  control={formCtx.control}
                  name="queueDsn"
                  label="Queue DSN"
                  type="url"
                  isRequired
                  placeholder="e.g. https://sqs.us-east-1.amazonaws.com/635461488753/test-queue-1"
                  helperText={<span>SQS queue DSN</span>}
                />

                <TextField
                  control={formCtx.control}
                  name="accessKey"
                  label="Access Key"
                  type="text"
                  isRequired
                />

                <TextField
                  control={formCtx.control}
                  name="secretKey"
                  label="Access Key Secret"
                  type="password"
                  isRequired
                />
              </>
            )}

            <Divider />
            <EventsList
              availableEvents={availableEvents?.data || []}
              control={formCtx.control}
              name="filterTypes"
              label={
                <Flex alignItems="center" justifyContent="space-between">
                  <span>Subscribe to events</span>
                  <StyledLink
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    color="interactive.accent"
                    to={routeResolver.getRoute("event-types")}
                  >
                    Event Catalog
                    <ArrowForwardIos style={{ fontSize: 15, marginLeft: 4 }} />
                  </StyledLink>
                </Flex>
              }
              emptyState="Receiving all events."
            />
            {orgSettings?.enableChannels && (
              <ChannelsAutocomplete
                label="Channels"
                name="channels"
                control={formCtx.control}
                helperText="Channels are an extra dimension of filtering messages orthogonal to
              event types. They are case-sensitive and only messages with the
              corresponding tag will be sent to this endpoint."
              />
            )}

            <Divider />
          </Stack>
          <GeneralFormErrors />
          <HStack mt={4} spacing={4}>
            <Button colorScheme="gray" as={Link} to={routeResolver.getRoute("endpoints")}>
              Cancel
            </Button>
            <SubmitButton isLoading={formCtx.formState.isSubmitting}>Create</SubmitButton>
          </HStack>
        </Collapse>
      </Box>
    </Form>
  );
}
