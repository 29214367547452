import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomThemeOverride } from "svix";

import { SvixRegion } from "@svix/common/constants";

import { Errors } from "./errors";

type Applications = {
  appId?: string;
  apps: { [key: string]: Application };
};

export type Application = {
  auth: { user?: User };
  embedConfig: EmbedConfig;
  settings: Settings;
  serverUrl: string;
  errors: Errors;
};

type User = {
  app: { id: string; name: string };
  token: string;
  oneTimeToken: string | undefined;
  region: SvixRegion;
};

type EmbedConfig = {
  displayName?: string;
  isEmbed: boolean;
  isReadOnly: boolean;
  fontFamily?: string;
  fontFamilyUrl?: string;
  baseFontSize?: number;
  primaryColor?: string;
  primaryColorLight?: string;
  primaryColorDark?: string;
  themeOverrides?: CustomThemeOverride;
  logoUrl?: string;
  hideUseSvixPlay: boolean;
  isIntegrationsMode: boolean;
  integrationButtonType?: string;
};

type Settings = {
  darkMode: boolean;
  enableTransformations: boolean;
};

const initialState: Applications = { apps: {} };

const slice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setAppId(state: Applications, action: PayloadAction<string>) {
      state.appId = action.payload;
      return state;
    },
    addApplication(state: Applications, action: PayloadAction<Application>) {
      state.apps[action.payload.auth.user!.app.id] = action.payload;
      return state;
    },
    setIsEmbed(state: Applications, action: PayloadAction<boolean>) {
      state.apps[state.appId!].embedConfig.isEmbed = action.payload;
      return state;
    },
    setIsIntegrationsMode(state: Applications, action: PayloadAction<boolean>) {
      state.apps[state.appId!].embedConfig.isIntegrationsMode = action.payload;
      return state;
    },
    setEmbedConfig(state: Applications, action: PayloadAction<EmbedConfig>) {
      state.apps[state.appId!].embedConfig = action.payload;
      return state;
    },
    setSettings(state: Applications, action: PayloadAction<Settings>) {
      state.apps[state.appId!].settings = action.payload;
    },
    logout(state: Applications, _action: PayloadAction<undefined>) {
      // here we invalidate auth but keep the application
      // so that our gates in MainRouter work correctly
      delete state.apps[state.appId!].auth.user;
      return state;
    },
    setDarkMode(state: Applications, action: PayloadAction<boolean>) {
      state.apps[state.appId!].settings.darkMode = action.payload;
      return state;
    },
  },
});

export const {
  setAppId,
  addApplication,
  setIsEmbed,
  setEmbedConfig,
  setSettings,
  logout,
  setDarkMode,
} = slice.actions;
export default slice.reducer;
