import { useState } from "react";
import {
  Box,
  Collapse,
  Text,
  WrapItem,
  Wrap,
  useRadioGroup,
  useDisclosure,
} from "@chakra-ui/react";
import { TemplateOut } from "svix";
import { TransformationTemplateApi } from "svix/dist/openapi";

import IntegrationTile from "@svix/common/widgets/IntegrationTile";

import { getSvix } from "src/api";
import { useAppQuery } from "src/hooks/api";
import TransformationTemplateForm from "./TemplateForm";

export default function TransformationTemplateScreen() {
  const [template, setTemplate] = useState<TemplateOut | undefined>();
  const { data: templateList } = useAppQuery(["transformationTemplates"], () => {
    const svix = getSvix();
    const api = new TransformationTemplateApi(svix._configuration);
    return api.v1TransformationTemplateList({ limit: 250 });
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "integration",
    defaultValue: "",
    onChange: (n) => {
      const chosenTemplate = templateList?.data.find((i) => i.id === n);
      if (chosenTemplate) {
        setTemplate(chosenTemplate);
        onOpen();
      } else {
        onClose();
      }
    },
  });
  const group = getRootProps();

  return (
    <Box maxW="50em">
      <Text fontSize="sm" pb={2}>
        Transformation templates are a customizable way to connect your webhooks to
        third-party services.
      </Text>
      <Text fontSize="md" mt={2} pb={2} fontWeight="semibold">
        Choose template
      </Text>
      <Box maxHeight="300px" mb={4}>
        <Wrap {...group}>
          {templateList?.data.map((tt) => {
            const radio = getRadioProps({ value: tt.id });

            return (
              <WrapItem key={tt.id}>
                <IntegrationTile
                  key={tt.id}
                  {...radio}
                  icon={tt.logo}
                  name={tt.name}
                  description={tt.description}
                  wrapper={{ w: "24em" }}
                />
              </WrapItem>
            );
          })}
        </Wrap>
      </Box>
      <Collapse in={isOpen}>
        {template && <TransformationTemplateForm template={template} />}
      </Collapse>
    </Box>
  );
}
