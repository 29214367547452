import * as React from "react";
import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBoolean,
  useColorModeValue,
} from "@chakra-ui/react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useController, useWatch } from "react-hook-form";

import useKeyboardShortcut from "@svix/common/hooks/keyboardShortcut";
import { setSearch } from "@svix/common/hooks/search";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";

import { useAllEventTypes } from "src/hooks/api";
import DateFilter, {
  CUSTOM_DATE_FILTER,
  EARLIER_TODAY_FILTER,
  LAST_MONTH_FILTER,
  LAST_WEEK_FILTER,
  NOW_FILTER,
  EARLIEST_DATE_FILTER,
  YESTERDAY_FILTER,
} from "./DateFilter/DateFilter";

export type FilterType = "date" | "eventType" | "channels" | "date-range" | "tags";

const beforeDateQuickChoices = [
  NOW_FILTER,
  EARLIER_TODAY_FILTER,
  YESTERDAY_FILTER,
  LAST_WEEK_FILTER,
  LAST_MONTH_FILTER,
  CUSTOM_DATE_FILTER,
];

const afterDateQuickChoices = [
  EARLIEST_DATE_FILTER,
  LAST_MONTH_FILTER,
  LAST_WEEK_FILTER,
  YESTERDAY_FILTER,
  EARLIER_TODAY_FILTER,
  CUSTOM_DATE_FILTER,
];

interface IFilterMenuProps {
  control: any;
  filterCount: number;
  filters: FilterType[];
}

export default function FilterMenu(props: IFilterMenuProps) {
  const { control, filters, filterCount } = props;
  const [showFilterMenu, setShowFilterMenu] = useBoolean();
  const filterInputRef = React.useRef<HTMLInputElement | null>(null);

  const badgeColor = useColorModeValue("gray.200", "gray.600");
  const toolbarColor = useColorModeValue("white", "gray.800");

  useKeyboardShortcut("t", null, setShowFilterMenu.on);

  const showEventTypeFilter = filters.includes("eventType");
  const showDateFilter = filters.includes("date");
  const showChannelsFilter = filters.includes("channels");
  const showTagsFilter = filters.includes("tags");

  // Save filters to query params
  const eventTypes = useWatch({ control: control, name: "eventTypes" });
  const channels = useWatch({ control: control, name: "channels" });
  const afterFilter = useWatch({ control: control, name: "after" });
  const beforeFilter = useWatch({ control: control, name: "before" });
  const tags = useWatch({ control: control, name: "tags" });

  const { data: availableEvents } = useAllEventTypes();

  React.useEffect(() => {
    setSearch({
      eventTypes: eventTypes?.join(","),
      channels,
      before: beforeFilter ? beforeFilter.value : undefined,
      after: afterFilter ? afterFilter.value : undefined,
      tags,
    });
  }, [eventTypes, channels, beforeFilter, afterFilter, tags]);

  return (
    <Popover
      initialFocusRef={filterInputRef}
      isOpen={showFilterMenu}
      onClose={setShowFilterMenu.off}
    >
      <PopoverTrigger>
        <Button
          onClick={setShowFilterMenu.toggle}
          bgColor={toolbarColor}
          colorScheme="gray"
          variant="outline"
          size="sm"
          leftIcon={<FilterListIcon style={{ fontSize: 16 }} />}
        >
          Filters
          {filterCount > 0 && (
            <Flex
              alignItems="center"
              justifyContent="center"
              ml={2}
              w={5}
              h={5}
              fontSize="sm"
              bgColor={badgeColor}
              borderRadius="full"
            >
              {filterCount}
            </Flex>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent mx={2} width={filters.length <= 3 ? "sm" : "md"}>
        <PopoverArrow />
        <PopoverBody p={3}>
          <Tabs isFitted colorScheme="brand">
            <TabList>
              {showEventTypeFilter && (
                <Tab fontWeight="500" fontSize="xs" px={0}>
                  Event Types
                </Tab>
              )}
              {showChannelsFilter && (
                <Tab fontWeight="500" fontSize="xs" px={0}>
                  Channel
                </Tab>
              )}
              {showTagsFilter && (
                <Tab fontWeight="500" fontSize="xs" px={0}>
                  Tags
                </Tab>
              )}
              {showDateFilter && (
                <>
                  <Tab fontWeight="500" fontSize="xs" px={0}>
                    After Date
                  </Tab>
                  <Tab fontWeight="500" fontSize="xs" px={0}>
                    Before Date
                  </Tab>
                </>
              )}
            </TabList>

            <TabPanels>
              {showEventTypeFilter && (
                <TabPanel p={0} mt={1}>
                  <EventsList
                    availableEvents={availableEvents?.data || []}
                    emptyState="Showing all event types."
                    filterInputRef={filterInputRef}
                    name="eventTypes"
                    control={control}
                  />
                </TabPanel>
              )}
              {showChannelsFilter && (
                <TabPanel p={0} mt={1}>
                  <TextFilter
                    placeholder="Filter by channel..."
                    filterInputRef={!showEventTypeFilter ? filterInputRef : undefined}
                    control={control}
                    name="channels"
                  />
                </TabPanel>
              )}
              {showTagsFilter && (
                <TabPanel p={0} mt={1}>
                  <TextFilter
                    placeholder="Filter by tag..."
                    filterInputRef={!showEventTypeFilter ? filterInputRef : undefined}
                    control={control}
                    name="tags"
                  />
                </TabPanel>
              )}
              {showDateFilter && (
                <TabPanel p={0} mt={1}>
                  <DateFilter
                    control={control}
                    name="after"
                    quickChoices={afterDateQuickChoices}
                  />
                </TabPanel>
              )}
              {showDateFilter && (
                <TabPanel p={0} mt={1}>
                  <DateFilter
                    control={control}
                    name="before"
                    quickChoices={beforeDateQuickChoices}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

interface ITextFilterProps {
  filterInputRef?: React.RefObject<HTMLInputElement>;
  placeholder: string;
  control: any;
  name: string;
  required?: boolean;
}

function TextFilter(props: ITextFilterProps) {
  const { name, control, filterInputRef, placeholder } = props;
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  const [filterStr, setFilterStr] = React.useState(value as string);

  return (
    <FormControl id={name} isRequired={props.required}>
      <InputGroup size="sm">
        <Input
          ref={filterInputRef}
          name={name}
          placeholder={placeholder}
          variant="filled"
          value={filterStr}
          onChange={(e) => setFilterStr(e.target.value)}
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              evt.preventDefault();
              onChange(filterStr.trim());
            }
          }}
        />
        <InputRightElement width="3em">
          <Button
            h="2em"
            size="xs"
            variant="outline"
            onClick={() => onChange(filterStr.trim())}
          >
            Go
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
}
