import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import {
  Brightness3,
  Brightness7,
  Notifications,
  Person,
  Menu as MenuIcon,
} from "@material-ui/icons";

import HelpBarItem from "@svix/common/widgets/HelpBarItem";
import Navbar from "@svix/common/widgets/Navbar";

import { getSvix } from "src/api";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { setDarkMode, logout } from "src/store/applications";
import { isEE, useLoadingManual } from "src/utils";

function NotificationsBarItem() {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          variant="appbar"
          aria-label="open notifications"
          data-cy="notification-menu"
        >
          <Notifications />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent mx={2}>
        <PopoverArrow />
        <PopoverBody p={3}>
          <Text>No new notifications.</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function AccountBarItem() {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const [isLoggingOut, , onLogoutClicked] = useLoadingManual(async () => {
    if (user && !isEE) {
      const sv = getSvix();
      await sv.authentication.logout();
    }
    dispatch(logout());
  }, []);

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          variant="appbar"
          aria-label="show account"
          data-cy="account-menu"
        >
          <Person />
        </MenuButton>
        <MenuList>
          <MenuGroup title={user?.app.name}>
            <MenuItem isDisabled={isLoggingOut} onClick={onLogoutClicked}>
              Logout
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </>
  );
}

function ColorModeToggle() {
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector((state) => state.settings.darkMode);

  return (
    <IconButton
      variant="appbar"
      aria-label="toggle dark mode"
      onClick={() => {
        dispatch(setDarkMode(!darkMode));
      }}
    >
      {darkMode ? (
        <Icon as={Brightness7} />
      ) : (
        <Icon transform="auto" rotate={9} as={Brightness3} />
      )}
    </IconButton>
  );
}

interface IAppBarProps {
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (isOpen: boolean) => void;
}

export default function AppBar(props: IAppBarProps) {
  const handleDrawerToggle = () => {
    props.setMobileDrawerOpen(!props.mobileDrawerOpen);
  };

  return (
    <Navbar bg="brand.500">
      <IconButton
        variant="appbar"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        display={["flex", "flex", "none"]}
      >
        <MenuIcon />
      </IconButton>
      <Flex flexGrow={1} />
      <Flex alignItems="center">
        <ColorModeToggle />
        <NotificationsBarItem />
        <HelpBarItem />
        <AccountBarItem />
      </Flex>
    </Navbar>
  );
}
