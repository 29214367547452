import * as React from "react";

import { useAppDispatch } from "src/hooks/store";
import { pushError } from "./store/errors";

export function useLoadingManual<T>(
  func_: (...args: any[]) => Promise<T>,
  deps: React.DependencyList
): [boolean, T | undefined, (...args: any[]) => Promise<T | undefined>] {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState<T>();

  const func = React.useCallback(
    async (...args: any[]): Promise<T | undefined> => {
      setLoading(true);
      try {
        const ret = await func_(...args);
        setValue(ret);
        return ret;
      } catch (e) {
        dispatch(pushError(e));
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    deps // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [loading, value, func];
}

export function useLoading<T>(
  func_: () => Promise<T>,
  deps: React.DependencyList
): [T | undefined, () => Promise<T | undefined>] {
  const ret = useLoadingManual(func_, deps);
  const value = ret[1];
  const func = ret[2];

  React.useEffect(() => {
    func();
  }, [func]);

  return [value, func];
}

export const isEE = import.meta.env.MODE === "ee";
