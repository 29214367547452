import { useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  SkeletonText,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Tag,
  Thead,
  Tr,
  Th,
  Tbody,
  Flex,
  Heading,
  Stack,
  Text,
  Divider,
  useBoolean,
} from "@chakra-ui/react";
import { MoreVert, Replay } from "@material-ui/icons";
import { useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { EndpointUpdate, ListResponseEndpointOut, MessageStatus } from "svix";
import { EndpointApi, EnvironmentSettingsApi } from "svix/dist/openapi";

import { useInterval } from "@svix/common/hooks/interval";
import { useSearch } from "@svix/common/hooks/search";
import {
  extractSvixPlayToken,
  getSvixPlayViewUrl,
  isSvixPlayUrl,
} from "@svix/common/play";
import { formatDateTime, humanize } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";
import ResourceNotFound from "@svix/common/widgets/ResourceNotFound";
import Stat from "@svix/common/widgets/Stat";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppPagination, useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import { isEE, useLoadingManual } from "src/utils";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";
import EndpointTableRow from "./EndpointRow";
import {
  DateFilterChoice,
  useInitialDateFilter,
  NOW_FILTER,
  EARLIEST_DATE_FILTER,
} from "../../components/DateFilter/DateFilter";
import FilterMenu from "../../components/FilterMenu";
import CustomHeaders from "../Endpoint/CustomHeaders";
import Description from "../Endpoint/Description";
import EndpointStatChart, { getFormattedStats } from "../Endpoint/EndpointStats";
import EndpointUrl from "../Endpoint/EndpointUrl";
import MessageStatusFilter from "../Endpoint/MessageStatusFilter";
import Channels from "../Endpoint/properties/Channels";
import EndpointEventTypes from "../Endpoint/properties/EventTypes";
import SigningSecret from "../Endpoint/properties/SigningSecret";
import RateLimit from "../Endpoint/RateLimit";
import RecoverModal from "../Endpoint/RecoverModal";
import ReplayModal from "../Endpoint/ReplayModal";
import SendExample from "../Endpoint/SendExample";
import TransformationCard from "../Endpoint/TransformationCard";

function useInitialStatus() {
  const status = useSearch("status");
  if (status) {
    return Number(status);
  }
  return undefined;
}

function useInitialEventTypes() {
  const savedVal = useSearch("eventTypes");
  if (!savedVal) {
    return [] as string[];
  }

  return savedVal.split(",");
}

const endpointTabs = ["overview", "advanced", "testing"];

export default function EndpointScreen() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const user = useAppSelector((state) => state.auth.user)!;
  const { endpId } = useParams<{ endpId: string }>();
  const queryKey = ["endpoints", endpId];
  const transformationsEnabled = useAppSelector(
    (state) => state.settings.enableTransformations
  );

  const [filterStatus, setFilterStatus] = useState<MessageStatus | undefined>(
    useInitialStatus()
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useBoolean();
  const [showReplayModal, setShowReplayModal] = useBoolean();
  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  const { data: endpoint, error } = useAppQuery(
    queryKey,
    async () => {
      const api = getSvix();
      return api.endpoint.get(user.app.id, endpId);
    },
    {
      placeholderData: () =>
        queryClient
          .getQueryData<ListResponseEndpointOut>("endpoints")
          ?.data.find((d) => d.id === endpId),
    }
  );

  const defaultValues = {
    eventTypes: useInitialEventTypes(),
    before: useInitialDateFilter("before", NOW_FILTER),
    after: useInitialDateFilter("after", EARLIEST_DATE_FILTER),
    channels: useSearch("channels") ?? "",
  };

  const formCtx = useForm({ defaultValues });

  let filterCount = 0;

  const eventTypes = useWatch({
    control: formCtx.control,
    name: "eventTypes",
    defaultValue: defaultValues.eventTypes,
  });
  filterCount += eventTypes.length;

  const afterDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "after",
    defaultValue: defaultValues.after,
  });
  if (afterDateFilter.value !== EARLIEST_DATE_FILTER.value) {
    filterCount++;
  }

  const beforeDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "before",
    defaultValue: defaultValues.before,
  });
  if (beforeDateFilter.value !== NOW_FILTER.value) {
    filterCount++;
  }

  const channel = useWatch({
    control: formCtx.control,
    name: "channels",
    defaultValue: defaultValues.channels,
  });
  if (channel) {
    filterCount++;
  }

  const [msgs, messagesCtx] = useAppPagination(
    [
      ...queryKey,
      "messages",
      String(filterStatus ?? "all"),
      afterDateFilter.value,
      beforeDateFilter.value,
      channel,
      eventTypes.join(","),
    ],
    async (iterator) => {
      const api = getSvix();
      return api.messageAttempt.listAttemptedMessages(user.app.id, endpId, {
        iterator,
        limit: 20,
        status: filterStatus,
        channel: channel || undefined,
        before: beforeDateFilter.getDate(),
        after: afterDateFilter.getDate(),
        eventTypes,
      });
    }
  );

  const { data: stats } = useAppQuery(
    [...queryKey, "stats"],
    async () => {
      const sv = getSvix();
      const config = sv._configuration;
      const endpApi = new EndpointApi(config);
      return endpApi.v1EndpointGetStats({
        appId: user.app.id,
        endpointId: endpId,
      });
    },
    {
      enabled: !!endpoint,
    }
  );

  const { data: orgSettings } = useAppQuery(
    ["orgSettings"],
    async () => {
      const sv = getSvix();
      const config = sv._configuration;
      const api = new EnvironmentSettingsApi(config);
      return api.v1EnvironmentGetSettings({});
    },
    {
      // since org settings should not likely to change frequently, don't refetch
      // on re-mount
      staleTime: Infinity,
    }
  );

  const [, , setDisabled] = useLoadingManual(
    async (disabled: boolean) => {
      const api = getSvix();
      const patchedEndpoint = { ...endpoint, disabled };
      if (endpoint!.filterTypes && endpoint!.filterTypes.length === 0) {
        delete patchedEndpoint["filterTypes"];
      }

      await api.endpoint.update(user.app.id, endpId, patchedEndpoint as EndpointUpdate);
      queryClient.invalidateQueries(queryKey);
    },
    [endpId, endpoint]
  );

  const [, , deleteEndpoint] = useLoadingManual(async () => {
    const api = getSvix();
    await api.endpoint.delete(user.app.id, endpId);
    history.replace(routeResolver.getRoute("endpoints"));
  }, []);

  useInterval(() => {
    // refetch the data if any nextAttempt has past
    if (msgs?.data.some((msg) => msg.nextAttempt && msg.nextAttempt < new Date())) {
      messagesCtx.refetch();
    }
  }, 5_000);

  const { hash } = useLocation();
  const tabsIndex = useMemo(() => {
    switch (hash) {
      case "#advanced":
        return 1;
      case "#testing":
        return 2;
      default:
        return 0;
    }
  }, [hash]);

  if (error) {
    return (
      <ResourceNotFound
        resourceName="endpoint"
        to={routeResolver.getRoute("endpoints")}
      />
    );
  }

  if (!endpoint) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <MetaTitle path={[humanize(endpId), "Endpoints", user.app.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("endpoints")}>
            Endpoints
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={endpId} />
          {endpoint.disabled && (
            <Tag size="md" colorScheme="red" ml={2}>
              Disabled
            </Tag>
          )}
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Box>
          <Menu placement="bottom-end">
            <MenuButton as={IconButton} variant="rounded" data-cy="options-button">
              <MoreVert />
            </MenuButton>
            <MenuList data-cy="options-menu">
              {isSvixPlayUrl(endpoint.url) && (
                <>
                  <MenuItem
                    as="a"
                    href={getSvixPlayViewUrl(extractSvixPlayToken(endpoint.url) || "")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Svix Play
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
              {!isEE && (
                <>
                  <MenuItem onClick={setShowRecoverModal.on}>
                    Recover failed messages...
                  </MenuItem>
                  <MenuItem onClick={setShowReplayModal.on}>
                    Replay missing messages...
                  </MenuItem>
                </>
              )}
              <ReadOnlyTooltip readOnly={isReadOnly}>
                <MenuItem
                  isDisabled={isReadOnly}
                  onClick={() => setDisabled(!endpoint.disabled)}
                >
                  {endpoint.disabled ? "Enable" : "Disable"} Endpoint
                </MenuItem>
              </ReadOnlyTooltip>
              <ReadOnlyTooltip readOnly={isReadOnly}>
                <MenuItem
                  isDisabled={isReadOnly}
                  textColor="text.danger"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete
                </MenuItem>
              </ReadOnlyTooltip>
            </MenuList>
          </Menu>
        </Box>
      </PageToolbar>
      <Grid
        gridTemplateColumns={{
          sm: "minmax(0, 1fr)",
          md: "minmax(0, 3fr) minmax(240px, 1fr)",
        }}
        gap={8}
      >
        <Tabs
          variant="enclosed"
          index={tabsIndex}
          onChange={(i) => history.push(`#${endpointTabs[i]}`)}
        >
          <EndpointUrl endpoint={endpoint} />
          <TabList>
            <Tab
              _selected={{
                border: "1px solid",
                borderColor: "background.modifier.border",
                borderBottomColor: "background.primary",
              }}
            >
              Overview
            </Tab>
            <Tab
              _selected={{
                border: "1px solid",
                borderColor: "background.modifier.border",
                borderBottomColor: "background.primary",
              }}
            >
              Advanced
            </Tab>
            <Tab
              _selected={{
                border: "1px solid",
                borderColor: "background.modifier.border",
                borderBottomColor: "background.primary",
              }}
            >
              Testing
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack spacing={4}>
                <Description endpoint={endpoint} />
                <Card title="Attempt Delivery Stats" mt={6}>
                  <EndpointStatChart
                    stats={getFormattedStats(stats)}
                    isLoading={!stats}
                  />
                </Card>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack spacing={4}>
                <RateLimit endpoint={endpoint} />
                <CustomHeaders endpoint={endpoint} />
                {transformationsEnabled && <TransformationCard endpoint={endpoint} />}
              </Stack>
            </TabPanel>
            <TabPanel>
              <SendExample endpoint={endpoint} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Stack spacing={4}>
          <Stat name="Version">{endpoint.version}</Stat>
          <Divider />
          <Stat name="Creation Date">{formatDateTime(endpoint.createdAt)}</Stat>
          <Divider />
          <Stat name="Last Updated">{formatDateTime(endpoint.updatedAt)}</Stat>
          {orgSettings?.enableChannels && (
            <>
              <Divider />
              <Channels endpoint={endpoint} />
            </>
          )}
          <Divider />
          <EndpointEventTypes endpoint={endpoint} />
          <Divider />
          <SigningSecret />
        </Stack>
      </Grid>

      <Flex alignItems="center" mt={12} mb={4} gridGap={2}>
        <Heading as="h2" size="sm">
          Attempted Messages
        </Heading>
        <Flex flexGrow={1} />
        <IconButton
          size="sm"
          aria-label="Refresh"
          variant="outline"
          isLoading={messagesCtx.isFetching}
          onClick={messagesCtx.refetch}
        >
          <Replay style={{ fontSize: 16 }} />
        </IconButton>
        <MessageStatusFilter value={filterStatus} onChange={setFilterStatus} />
        <FilterMenu
          filters={
            orgSettings?.enableChannels
              ? ["eventType", "date", "channels"]
              : ["eventType", "date"]
          }
          control={formCtx.control}
          filterCount={filterCount}
        />
      </Flex>
      <Table
        variant="hover"
        emptyState={
          filterCount > 0 || filterStatus !== undefined ? (
            <Text variant="muted">No messages exist matching the current filter</Text>
          ) : (
            <>
              <Text variant="muted">This endpoint has not received any messages yet</Text>
            </>
          )
        }
        response={msgs}
        requestElems={messagesCtx}
        size="sm"
        horizScroll
      >
        <Thead>
          <Tr>
            <Th />
            <Th>Event Type</Th>
            {orgSettings?.enableChannels && <Th>Channels</Th>}
            <Th>Message ID</Th>
            <Th>Timestamp</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {msgs?.data.map((msg) => (
            <EndpointTableRow
              key={msg.id}
              endpoint={endpoint}
              msg={msg}
              enableChannels={orgSettings?.enableChannels ?? false}
            />
          ))}
          {!msgs && (
            <Tr>
              <TableCell />
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell />
            </Tr>
          )}
        </Tbody>
      </Table>
      <RecoverModal
        isOpen={showRecoverModal}
        onClose={setShowRecoverModal.off}
        endpoint={endpoint}
      />
      <ReplayModal
        isOpen={showReplayModal}
        onClose={setShowReplayModal.off}
        endpoint={endpoint}
      />
      <ConfirmationDialog
        title="Deletion confirmation"
        isOpen={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onOk={deleteEndpoint}
        labelOk="Delete"
        colorScheme="red"
      >
        Are you sure you would like to permanently delete this endpoint?
      </ConfirmationDialog>
    </>
  );
}
