import {
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  CircularProgress,
  Heading,
  Link as StyledLink,
  Skeleton,
  SkeletonText,
  Tag,
  TagLabel,
  Thead,
  Tr,
  Th,
  Tbody,
  Tooltip,
} from "@chakra-ui/react";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  EndpointApi,
  EndpointOut,
  EndpointStats,
  TransformationTemplateApi,
} from "svix/dist/openapi";

import Button from "@svix/common/widgets/Button";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { useAppPagination, useAppQuery } from "src/hooks/api";
import useFeatureFlag from "src/hooks/featureFlag";
import { useAppSelector } from "src/hooks/store";
import sqsIcon from "src/img/sinks/aws-sqs.svg";
import rabbitMQIcon from "src/img/sinks/rabbitmq.svg";
import webhookIcon from "src/img/webhook.svg";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";
import { getSvix, SinksApi } from "../../api";
import { routeResolver } from "../../App";
import { templateIdMetadataField } from "../EndpointCreate/TemplateForm";

function StatsChip(props: { stat?: EndpointStats }) {
  const { stat } = props;
  if (!stat) {
    return <CircularProgress isIndeterminate size="20px" mr={4} />;
  }

  const total = stat.fail + stat.pending + stat.success + stat.sending;
  const errorRate = total > 0 ? (stat.fail + stat.pending) / total : 0;

  return (
    <Tag borderRadius="full" size="sm" colorScheme={errorRate > 0 ? "red" : "green"}>
      <TagLabel>{(errorRate * 100).toFixed(1)}%</TagLabel>
    </Tag>
  );
}

function EndpointRow(props: { endpoint: EndpointOut }) {
  const { endpoint } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const to = routeResolver.getRoute("endpoints._id", { endpId: endpoint.id });
  const templateId = endpoint.metadata[templateIdMetadataField];

  const { data: stats } = useAppQuery(
    ["endpoints", endpoint.id, "stats"],
    async () => {
      const dh = getSvix();
      const config = dh._configuration;
      const endpApi = new EndpointApi(config);
      return endpApi.v1EndpointGetStats({
        appId: user.app.id,
        endpointId: endpoint.id,
      });
    },
    {
      staleTime: 1000 * 60,
    }
  );

  const { data: template, isLoading: templateLoading } = useAppQuery(
    ["transformationTemplates", templateId],
    async () => {
      const dh = getSvix();
      const ttApi = new TransformationTemplateApi(dh._configuration);
      return ttApi.v1TransformationTemplateGet({ transformationTemplateId: templateId });
    },
    {
      enabled: !!templateId,
    }
  );

  return (
    <Tr opacity={endpoint.disabled ? "60%" : "100%"}>
      <TableCell to={to} scope="row" maxW={0} py={1}>
        <HStack spacing={4}>
          <Skeleton isLoaded={!templateLoading}>
            {template && (
              <Tooltip
                label={`Created using the ${template.name} transformation template`}
              >
                <Image
                  src={template.logo}
                  alt={`${template.name} icon`}
                  width="20px"
                  height="20px"
                />
              </Tooltip>
            )}
            {!template && (
              <Image src={webhookIcon} alt="Webhook icon" width="20px" height="20px" />
            )}
          </Skeleton>
          <Stack spacing={0.5} minH="36px" justifyContent="center">
            <Text lineHeight="normal">{endpoint.url}</Text>
            {endpoint.disabled && (
              <Tag size="sm" colorScheme="red" ml={2}>
                Disabled
              </Tag>
            )}
            <Text variant="caption" noOfLines={1} isTruncated lineHeight="normal">
              {endpoint.description}
            </Text>
          </Stack>
        </HStack>
      </TableCell>
      <TableCell to={to} isNumeric>
        <StatsChip stat={stats} />
      </TableCell>
    </Tr>
  );
}

export default function EndpointsScreen() {
  const user = useAppSelector((state) => state.auth.user)!;
  const sinksEnabled = useFeatureFlag("sinks");

  const [endpoints, endpointsCtx] = useAppPagination("endpoints", async (iterator) => {
    const api = getSvix();
    return api.endpoint.list(user.app.id, { iterator });
  });

  const [sinks, _] = useAppPagination("sinks", async () => {
    if (!sinksEnabled) {
      return { data: [] };
    }

    const api = SinksApi();
    return api.listSinks(user.app.id);
  });

  const httpEndpoints = endpoints?.data.filter((e) => {
    if (sinks && sinks.data?.find((s: any) => s.id === e.id)) {
      return false;
    }
    return true;
  });

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  return (
    <>
      <MetaTitle path={["Endpoints", user.app.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem>Endpoints</BreadcrumbItem>
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <ReadOnlyTooltip readOnly={isReadOnly}>
          <Button
            as={Link}
            size="sm"
            isDisabled={isReadOnly}
            to={isReadOnly ? "" : routeResolver.getRoute("endpoints.new")}
            variant="outlineSecondary"
            leftIcon={<Add />}
          >
            Add Endpoint
          </Button>
        </ReadOnlyTooltip>
      </PageToolbar>
      <Table
        data-cy="endpoints-table"
        response={endpoints}
        requestElems={endpointsCtx}
        variant="hover"
        emptyState={
          <Box my={12}>
            <Heading size="sm" mb={2}>
              Set up an endpoint to get started
            </Heading>
            <Text fontSize="sm">
              For a list of events you can subscribe to, take a look at the{" "}
              <StyledLink
                as={Link}
                color="interactive.accent"
                to={routeResolver.getRoute("event-types")}
                fontWeight="semibold"
              >
                Event Catalog
              </StyledLink>
              .
            </Text>
          </Box>
        }
      >
        <Thead>
          <Tr>
            <Th width="80%">URL</Th>
            <Th isNumeric minW="70px" whiteSpace="nowrap">
              Error Rate
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {httpEndpoints?.map((endpoint) => (
            <EndpointRow key={endpoint.id} endpoint={endpoint} />
          ))}
          {sinks?.data?.map((sink: any) => (
            <SinkRow key={sink.id} sink={sink} />
          ))}
          {!endpoints && (
            <Tr>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
}

const SinkIcons: { [k: string]: string } = {
  rabbitMQ: rabbitMQIcon,
  sqs: sqsIcon,
};

const SinkRow = (props: { sink: any }) => {
  const { sink } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const to = routeResolver.getRoute("sinks._id", { endpId: sink.id });

  const { data: stats } = useAppQuery(["endpoints", sink.id, "stats"], async () => {
    const dh = getSvix();
    const config = dh._configuration;
    const endpApi = new EndpointApi(config);
    return endpApi.v1EndpointGetStats({
      appId: user.app.id,
      endpointId: sink.id,
    });
  });

  return (
    <Tr opacity={sink.disabled ? "60%" : "100%"}>
      <TableCell to={to} scope="row" maxW={0} py={1}>
        <HStack spacing={4}>
          <Image
            src={SinkIcons[sink.type]}
            alt="Webhook icon"
            width="20px"
            height="20px"
          />
          <Stack spacing={0.5} minH="36px" justifyContent="center">
            <HStack>
              <Text lineHeight="normal">{sink.uri || sink.queueDsn}</Text>
              <Tag>{sink.type}</Tag>
            </HStack>
            {sink.disabled && (
              <Tag size="sm" colorScheme="red" ml={2}>
                Disabled
              </Tag>
            )}
            <Text variant="caption" noOfLines={1} isTruncated lineHeight="normal">
              {sink.description}
            </Text>
          </Stack>
        </HStack>
      </TableCell>
      <TableCell to={to} isNumeric>
        <StatsChip stat={stats} />
      </TableCell>
    </Tr>
  );
};
